<template>
  <div class="test2-container">
    <Breadcrumb></Breadcrumb>
    <p>test2 page - 嵌套路由示例.</p>
    <div class="childrens">
      <router-link class="children" :to="{ name: 'test2-1' }">test2-1</router-link>
      <router-link class="children" :to="{ name: 'test2-2' }">test2-2</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
  export default {
    components: {
      Breadcrumb
    }
  }
</script>

<style lang="less" scoped>
.test2-container {
  --children: 60px;
  .childrens {
    display: flex;
    gap: 10px;
    .children {
      width: 120px;
      height: var(--children);
      text-align: center;
      line-height: var(--children);
      background-color: bisque;
    }
  }
}
</style>