// 暂时弃用，改为Breadcrumb组件内监听$route
export const breadcrumb = {
  state: () => ({
    routeTo: {},
    routeFrom: {},
  }),
  mutations: {
    routeReset(state){
      state.routeTo = {}
      state.routeFrom = {}
    },
    routeChange(state, payload) {
      state.routeTo = payload.routeFrom;
      state.routeFrom = payload.routeFrom;
    }
  }
}
