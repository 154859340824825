import Home from '@/views/Home'
import About from '@/views/About'
import Demo from '@/views/Demo'
import Code from '@/views/Code'
import Test2 from '@/views/Test2'
import Test2_1 from '@/views/Test2/Test2-1'
import Test2_2 from '@/views/Test2/Test2-2'
import NotFound from '@/views/NotFound'
import User from '@/views/User'
import Login from '@/views/User/Login'
import Register from '@/views/User/Register'

export const routes = [
  { path: '/', component: Home, name: 'home', meta: { breadcrumbName: '首页', nav: true } },
  { path: '/demo', component: Demo, name: 'demo', meta: { requiresAuth: true, breadcrumbName: '特效重写', nav: true } },
  { path: '/code', component: Code, name: 'code', meta: { requiresAuth: true, breadcrumbName: '代码段', nav: true } },
  {
    path: '/test2', component: Test2, name: 'test2', meta: { breadcrumbName: 'test2', nav: true }, 
    children: [
      {
        path: 'test2-1',
        component: Test2_1,
        name: 'test2-1',
        meta: { breadcrumbName: 'test2-1', nav: true }
      },
      {
        path: 'test2-2',
        component: Test2_2,
        name: 'test2-2',
        meta: { breadcrumbName : 'test2-2', nav: true }
      }
    ]
  },
  { path: '/user', component: User, name: 'user', meta: { requiresAuth: true, breadcrumbName: 'user' } },
  { path: '/login', component: Login, name: 'login', meta: { breadcrumbName: 'login' } },
  { path: '/register', component: Register, name: 'register', meta: { breadcrumbName: 'register' } },
  { path: '/about', components: { default: About }, name: 'about', meta: { requiresAuth: true, breadcrumbName: '关于', nav: true } }, // 写法效果同 { component: About }，注意后缀的s，参考 https://v3.router.vuejs.org/zh/guide/essentials/named-views.html
  { path: '*', component: NotFound, name: 'nf404', meta: { breadcrumbName: '404' } },
]
