import VueRouter from 'vue-router'
import store from '@/store/index'
import { routes } from './routes'

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  console.log('我是全局路由守卫beforeEach, to:', to, 'from:', from);
  to.meta.requiresAuth && !store.state.user.user.name ? next({ name: 'login', query: { redirect: to.fullPath } }) : next();
})

export { router }
