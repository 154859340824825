import { router } from '@/router'
import { Message } from 'ant-design-vue';
import { login } from '@/api/user'

export const user = {
  state: {
    count: 0,
    loading: false,
    user: {
      name: null,
      sex: null,
      avatar: null
    }
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    login(state, payload) {
      // console.log('mutations payload', payload);
      state.user = payload;
    },
    logout(state) {
      state.user = {
        name: null,
        sex: null,
        avatar: null
      }
    }
  },
  actions: {
    login(context, payload) {  // Action 函数接受一个与 store 实例具有相同方法和属性的 context 对象
      // console.log('actions payload', payload);
      login(payload).then(res=>{
        const { data } = res;
        if (data.code < 300) {
          // 登录成功
          context.commit('login', data.data)
          Message.success(data.message)
          router.push(payload.redirect || '/')
        } else {
          Message.error(data.message)
        }
      });
    },
    logout(context) {
      return new Promise(resolve => {
        setTimeout(() => {
          context.commit('logout');
          router.push({ name: 'login' })
          resolve()
        }, 1100)
      })
    }
  }
}