<template>
  <div id="app">
    <Layout style="min-width: 1250px;"></Layout>
  </div>
</template>

<script>
import Layout from './layout/Layout.vue'

export default {
  name: 'App',
  components: {
    Layout
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul, li {
  list-style: none;
}
a, a:hover{
  color: inherit;
}
</style>
