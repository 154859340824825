<template>
  <div class="breadcrumb-container">
    <a-breadcrumb>
      <a-breadcrumb-item><router-link :to="{ name: 'home' }">Home</router-link></a-breadcrumb-item>
      <a-breadcrumb-item v-for="item in $route.matched" :key="item.path">
        <span v-if="$route.matched.indexOf(item) === $route.matched.length - 1">
          {{ item.meta.breadcrumbName }}
        </span>
        <router-link v-else :to="{ name: item.name }">
          {{ item.meta.breadcrumbName }}
        </router-link>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>

export default {
  data() {
    return {
      
    }
  },
  mounted() {

  },
  watch: {
    // $route: {
    //   handler(to, from) {
    //     console.log('this.$route', to, from);
    //   },
    //   immediate: true
    // }
  }
}
</script>

<style lang="less" scoped>

</style>