<template>
  <div>
    test2-2 page
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped></style>