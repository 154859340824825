<template> <!-- header -->
  <div class="header-container">
    <div class="logo">
      <img src="/favicon.ico" alt="">
    </div>
    <div class="nav-list">
      <ul class="nav-list-ul">
        <router-link :to="nav.path" class="nav-list-li" :ref="nav.children && nav.children.length > 0 ? 'dropdown' : null" 
          v-for="nav in routes" :key="nav.path">
          <div>
            {{ nav.meta.breadcrumbName }}
          </div>
          <div v-if="nav.children && nav.children.length > 0" class="nav-list-li-dropdown-ctn" ref="dropdownCtn">
            <router-link :to="{ name: dropdown.name }" class="nav-list-li-dropdown-list" v-for="dropdown in nav.children"
              :key="dropdown.meta.breadcrumbName">
              {{ dropdown.meta.breadcrumbName }}
            </router-link>
          </div>
        </router-link>
      </ul>
    </div>
    <div class="user">
      <UserHeader></UserHeader>
    </div>
  </div>
</template>

<script>
import { setHeightTransitional } from '@/utils/default'
import UserHeader from '@/components/UserHeader'
import { routes } from '@/router/routes'

export default {
  data() {
    return {
      routes,
      // navList: [  // 弃用，改为根据route动态生成导航栏
      //   { title: "主页", link: '/', subItem: [] },
      //   { title: "特效重写", link: '/demo', subItem: [] },
      //   { title: "代码段", link: '/code', subItem: [] },
      //   {
      //     title: "示例1", link: '', subItem: [
      //       { title: "示例1-1", link: '/test1-1' },
      //       { title: "示例1-2", link: '/test1-2' },
      //       { title: "示例1-3", link: '/test1-3' },
      //     ]
      //   },
      //   {
      //     title: "示例2", link: '/test2', subItem: [
      //       { title: "示例2-1", link: '/test2/test2-1' },
      //       { title: "示例2-2", link: { name: 'test2-2' } }, // 两种写法示例
      //     ]
      //   },
      //   { title: "关于", link: '/about', subItem: [] },
      // ]
    }
  },
  mounted() {
    setHeightTransitional(this.$refs.dropdown[0].$el, this.$refs.dropdownCtn[0])
    // setHeightTransitional(this.$refs.dropdown[1].$el, this.$refs.dropdownCtn[1])
    this.routes = this.routes.filter((item) => {
      return item.meta.nav;
    })
    
  },
  components: {
    UserHeader
  }
}
</script>

<style lang="less" scoped>
.header-container {
  --height: 64px;

  display: flex;

  .logo {
    background-color: lightcyan;
    width: 64px;
    height: var(--height);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-list {
    flex-grow: 1;
    padding: 0 24px;

    .nav-list-ul {
      display: flex;
      height: var(--height);

      .nav-list-li {
        width: 90px;
        text-align: center;
        cursor: pointer;

        // display: grid;
        // grid-template-rows: 64px 0fr;

        &:hover {
          background-color: #eee;
          // grid-template-rows: 64px 1fr;
        }

        .nav-list-li-dropdown-ctn {
          height: 0;
          overflow: hidden;
          position: relative;
          z-index: 9;
          cursor: pointer;
          
        }

        .nav-list-li-dropdown-list {
          // height: 40px;
          // line-height: 40px;
          background-color: #eee;

          &:hover {
            background-color: #ddd;
          }
        }
      }
    }
  }

  .user {
    box-sizing: border-box;
    height: var(--height);
    width: 128px;
    background-color: #eee;
    // padding: 4px;
    text-align: center;
    display: flex;
    justify-content: space-around;
  }
}

a, a:hover{
  display: block;
  color: inherit;
}
</style>