<template>
  <div>
    test2-1 page
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="less" scoped></style>